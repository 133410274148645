<template>
  <div class="readme-article">
    <h2 id="客户标签">3.客户标签</h2>
    <h3 id="标签列表">3.1标签列表</h3>
    <p>查看标签列表，包含标签组名称、可见范围，子标签等信息。</p>
    <p>
      <img src="@/assets/img/scrm/4-8.png" />
    </p>
    <h3 id="标签编辑">3.2标签编辑</h3>
    <p>
       标签组添加编辑，支持设置可用部门，不填默认所有部门。
    </p>
    <p>
      <img src="@/assets/img/scrm/4-9.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm4-3",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>